import { useEffect, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { Button } from 'rsuite';
import defaultUser from '../assets/images/defaultuser.png';
import { getEmployees } from '../services/Employees';
import Loader from '../components/Loader';
const Employees = () => {

    const [staffs, setStaffs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchEmployees = async () => {
            const employees = await getEmployees();
            setStaffs(employees.data);
            setIsLoading(false);

        }
        fetchEmployees();
    }, []);

    // const staffs = StaffData;

    return (
        <>
            {
                isLoading
                    ? <Loader />
                    : <div>
                        <div className="flex justify-between mb-4">
                            <div className="text-lg">Employees</div>
                            <Link to='add'>
                                <Button appearance="primary" className='bg-blue-700 hover:bg-blue-800'>+ Add Employee</Button>
                            </Link>
                        </div>
                        <form className='mb-4'>
                            <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Your Email</label>
                            <div className="relative">
                                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                </div>
                                <input type="search" id="search" className="block p-2.5 outline-none pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 " placeholder="Search" required />
                            </div>
                        </form>
                        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-4">
                            {staffs?.map((data: any, index: number) =>
                                <Link to={`details/${data.name}`} className='hover:no-underline focus:no-underline group' key={index}>
                                    <div className='shadow-lg rounded-lg bg-white' key={index}>
                                        <div className="flex gap-2 p-2 items-center">
                                            <div className="flex h-20 w-20">
                                                {
                                                    data.avatar
                                                        ? <img src={data.avatar} onError={(e: any) => { e.target.src = defaultUser }} alt="avatar" className='rounded-full border border-solid border-gray-300  w-20 h-20' />
                                                        : <img src={defaultUser} alt="default_avatar" className='rounded-full border border-solid border-gray-300 object-cover h-full w-full' />
                                                }
                                            </div>

                                            <div>
                                                <div className='font-semibold'>{data.first_name} {data.last_name}</div>
                                                <div className='text-sm text-gray-400'>Staff # {data.employee_number}</div>
                                                <div className='text-sm text-gray-500 w-full overflow-ellipsis whitespace-nowrap overflow-hidden'>{data.designation}</div>
                                            </div>
                                            <div className='ml-auto'>
                                                <IoIosArrowForward size={30} color='#b5b5b5' className='group-hover:fill-blue-400' />
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
            }
        </>
    )
}

export default Employees