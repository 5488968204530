import { differenceInDays } from 'date-fns';
import moment from 'moment';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { AiFillPlusCircle } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FiDownload, FiEdit3, FiRefreshCcw, FiTrash2 } from 'react-icons/fi';
import { useMatch } from 'react-router-dom';
import { Button, Dropdown, Popover, Table, Whisper } from 'rsuite';
import { DOCUMENTCOLUMNS } from '../constants/DOCUMENTS';
import { DocumentModel } from '../models/EMP_DOCS';
import { getEmployeeDocs } from '../services/Employees';
import AddEditPopup from './AddEditPopup';
import Popup from './Popup';
import { FILELIST } from '../constants/INITIAL_STATES';
const { Column, HeaderCell, Cell } = Table;

const MenuPopover = forwardRef(({ data, onSelect, ...rest }: any, ref) => (
    <Popover ref={ref} {...rest} full>
        <Dropdown.Menu onSelect={onSelect}>
            <Dropdown.Item eventKey={1}>
                <div className="flex items-center">
                    <FiEdit3 /><div className='pl-1'>Edit</div>
                </div>
            </Dropdown.Item>
            <Dropdown.Item eventKey={2}>
                <div className="flex items-center">
                    <FiTrash2 /><div className='pl-1'>Delete</div>
                </div>
            </Dropdown.Item>
            <Dropdown.Item eventKey={3}>
                <div className="flex items-center">
                    <FiDownload /><div className='pl-1'>Download</div>
                </div>
            </Dropdown.Item>
            {
                data.status === 'Expired' &&
                <Dropdown.Item eventKey={4}>
                    <div className="flex items-center">
                        <FiRefreshCcw /><div className='pl-1'>Renew</div>
                    </div>
                </Dropdown.Item>
            }
        </Dropdown.Menu>
    </Popover>
));

const EmpDocuments = () => {
    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortType, setSortType] = useState<any>();
    const [editRow, setEditRow] = useState<DocumentModel>();
    const [isLoading, setIsLoading] = useState(true);
    const [popupState, setPopupState] = useState<{ delete: boolean, edit: boolean, add: boolean }>({ delete: false, edit: false, add: false });
    const [tableDta, setTableData] = useState<DocumentModel[]>([]);
    const displayColumns = DOCUMENTCOLUMNS;
    const ref = useRef<any>();
    const match = useMatch('employees/details/:id');

    useEffect(() => {
        const getEmployeeDoc = async () => {
            const data = (await getEmployeeDocs(match?.params.id)).data;
            console.log("🚀 ~ file: EmpDocuments.tsx:59 ~ getEmployeeDoc ~ data:", data)
            setTableData(data);
            setIsLoading(false);
        }
        getEmployeeDoc();
    }, [match])


    const getData = () => {

        if (sortColumn && sortType) {
            return tableDta.sort((a, b) => {
                let x: any = a[sortColumn as keyof DocumentModel];
                let y: any = b[sortColumn as keyof DocumentModel];
                if (typeof x === 'string') {
                    x = x.charCodeAt(0);
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt(0);
                }
                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }
        return tableDta;
    };


    const handleSortColumn = (sortColumn: string, sortType: any) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }

    const onSelect = (id: any, data: any) => {
        console.log("🚀 ~ file: EmpDocuments.tsx:97 ~ onSelect ~ id:", id)
        console.log('🚀 ~ file: EmpDocuments.tsx ~ line 82 ~ onSelect ~ data', data);
        switch (id) {
            case 0:
                setEditRow(undefined);
                setPopupState({ ...popupState, add: true });
                break;
            case 1:
                setEditRow(data);
                setPopupState({ ...popupState, edit: true });
                break;
            case 2: setPopupState({ ...popupState, delete: true });
                break;
            default:
                break;
        }
        ref.current.close();
    }

    const handlePopupClose = (params: any) => {
        if (params.target.name === 'ok') {
            console.log('Delete confirmation');
        } else {
            console.log('Discard changes');
        }
        setPopupState({ ...popupState, delete: false });
    }



    return (
        <div className='overflow-y-hidden'>
            <div className="flex justify-end my-2" >
                <Button appearance="primary" onClick={() => onSelect(0, {})}>
                    <div className="flex align-center">
                        <AiFillPlusCircle size={20} className='mr-1' /> Add Document
                    </div>
                </Button>
            </div>
            <Table
                data={getData()}
                loading={isLoading}
                height={300}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
            >
                {displayColumns.map(column => {
                    const { key, label, ...rest } = column;
                    return (
                        <Column {...rest} key={key} sortable>
                            <HeaderCell>{label}</HeaderCell>
                            <Cell dataKey={key} />
                        </Column>
                    );
                })}
                <Column key='status' sortable>
                    <HeaderCell>Status</HeaderCell>
                    <Cell>
                        {rowData =>
                            <div className={`p-0.5 text-center text-xs rounded  ${rowData?.status === 'Expired' ? 'text-red-600 bg-red-200' : 'text-green-600 bg-green-200'}`}>{rowData?.status.toUpperCase()}</div>
                        }
                    </Cell>
                </Column>
                <Column key='expiresIn' sortable>
                    <HeaderCell>Expires In</HeaderCell>
                    <Cell>{rowData => `${differenceInDays(moment(rowData.expiry_date, 'YYYY-MM-DD').toDate(), new Date())} days`}</Cell>
                </Column>
                <Column key='action'>
                    <HeaderCell>Action</HeaderCell>
                    <Cell className='text-end'>
                        {rowData =>
                            <Whisper ref={ref} trigger="click" placement='bottom' speaker={<MenuPopover data={rowData} onSelect={(id: number) => onSelect(id, rowData)} />}>
                                <span className='flex justify-center'>
                                    <BsThreeDotsVertical size={18} className='text-gray-400 hover:text-gray-800 cursor-pointer' />
                                </span>
                            </Whisper>
                        }
                    </Cell>
                </Column>
            </Table>
            <Popup open={popupState.delete} handleClose={handlePopupClose} />
            <AddEditPopup open={popupState.edit || popupState.add} data={editRow} handleClose={() => setPopupState({ ...popupState, edit: false, add: false })} />
        </div >
    )
}

export default EmpDocuments