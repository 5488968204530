import { memo, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip, Whisper } from 'rsuite';
import { APP_CONSTANTS } from '../constants/APP_CONSTANTS';
import { MENU_ITEMS } from '../constants/MENU_ITEMS';
import { useMenuContext } from '../utils/MenuContextProvider';
import { BsChevronExpand } from "react-icons/bs";
import CompanyTile from './CompanyTile';

const items = MENU_ITEMS;

const Sidebar = () => {

    
    console.count('Rendered');

    const { activeMenu, setActiveMenuItem } = useMenuContext();
    const [activeIndex, setActiveIndex] = useState(0);
    const location = useLocation();

    const setActiveItem = (index: number, label: string) => {
        setActiveIndex(index);
        setActiveMenuItem(label)
    };

    useEffect(() => {
        const path = location.pathname.split("/")[1];
        switch (path) {
            case '':
                setActiveIndex(0);
                setActiveMenuItem('Dashboard')
                break;
            case 'dashboard':
                setActiveIndex(0);
                setActiveMenuItem('Dashboard')
                break;
            case 'employees':
                setActiveIndex(1);
                setActiveMenuItem('Employees')
                break;
            case 'documents':
                setActiveIndex(2);
                setActiveMenuItem('Documents')
                break;
            default:
                break;
        }
    }, [location.pathname])


    return (
        <div className='sticky top-0 bg-white h-screen'>
            <div className={`flex flex-col h-full shadow-lg ${activeMenu ? 'w-56 ' : 'w-full'}`}>
                {activeMenu
                    ? <div className='text-center border-b-2 mx-3 text-2xl py-5 sm:py-2 font-bold text-gray-700'>{APP_CONSTANTS.APPNAME}</div>
                    : <div className='text-center border-b-2 mx-3 text-2xl py-5 sm:py-2 font-bold text-gray-700'>P</div>

                }
                <CompanyTile activeMenu={activeMenu} />
                <ul className={`flex flex-col w-full list-none overflow-hidden text-gray-800 ${activeMenu ? '' : 'items-center'}`}>
                    {items.map((item, index) => (
                        <Whisper key={index} placement="rightStart" controlId="control-id-active" trigger="hover" speaker={<Tooltip arrow={false} className='!bg-slate-200 !text-slate-500' >{item.label}</Tooltip>}>
                            <Link to={item.path} onClick={() => { setActiveItem(index, item.label) }} className='hover:no-underline focus:no-underline mx-3'>
                                <li
                                    className={
                                        'flex  items-center p-4 sm:p-2 rounded-lg mb-1 hover:bg-blue-50 hover:cursor-pointer hover:text-blue-500 ' +
                                        (activeIndex === index &&
                                            'bg-blue-50 cursor-pointer text-blue-500')
                                    }

                                >
                                    <div className="text-lg">
                                        {activeIndex === index ? <item.iconSolid size={18} /> : <item.icon size={18} />}
                                    </div>
                                    {(activeMenu &&
                                        <p className="font-sans text-sm font-semibold text-left px-3">
                                            {item.label}
                                        </p>
                                    )}
                                </li>
                            </Link>
                        </Whisper>
                    ))}
                </ul>
            </div>
        </div>
    )
}

const SidebarMemo = memo(Sidebar);
export default SidebarMemo;