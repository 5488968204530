import { forwardRef, useState } from "react";
import { BiCloudUpload } from "react-icons/bi";

const FileInputControl= forwardRef((props: any, ref: any) => {
    const { onChange, context, docType, folder } = props;
    const [fileName, setFileName] = useState('');

    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setFileName(file.name);
            const timestamp = new Date().getTime();
            const randomString = Math.random().toString(36).substring(2, 8); // Random string of length 6
            const fileName = `${context}_${timestamp}_${randomString}.${file.name.split(".").pop()}`;
            const formData = new FormData();
            formData.append('file', file, fileName);
            formData.append('doctype', docType);
            formData.append('folder', folder);
            console.log("🚀 ~ file: FileInputControl.tsx:16 ~ handleFileChange ~ formData:", formData)
            onChange(formData);
        }
    }

    return (
        <div>
            <input ref={ref} type="file" id="upload" name='avatar' accept='.png, .jpeg, .jpg' onChange={handleFileChange} hidden />
            <label htmlFor="upload" className='inline-block bg-gray-200 rounded px-1 w-full py-1 cursor-pointer text-blue-600' >
                <div className="flex items-center gap-2">
                    <div className='flex items-center gap-1 bg-blue-500 py-1 px-2 text-white rounded'><BiCloudUpload className='inline-block' size={16} /> Browse file</div>
                    <div className="flex-1 whitespace-nowrap overflow-hidden overflow-ellipsis">{fileName || 'No file selected'}</div>
                </div>
            </label>
        </div>
    )
});

export default FileInputControl;