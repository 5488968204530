import React from 'react'

const ErrorChip = () => {
  return (
    <div className='p-2 w-full rounded bg-red-200 text-red-600 mt-4'>
        <p>3 Documents will expire in 30 Days</p>
    </div>
  )
}

export default ErrorChip