import { useEffect, useRef, useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { Button, ButtonToolbar, DatePicker, Form, InputPicker } from 'rsuite';
import { EDUCATION, GENDER, MARITAL, RELIGION } from '../constants/BIODATA';
import { PERSONALFORM } from '../constants/INITIAL_STATES';
import { PERSONAL_SCHEMA } from '../helpers/Validators';
import { PERSONALMODEL } from '../models/BIODATA';
import FileInputControl from './FileInputControl';

const PersonalInfo = ({ setStep, onChange, formData }: any) => {

    const genderData = GENDER;
    const maritalData = MARITAL;
    const education = EDUCATION;
    const religion = RELIGION;
    const schema = PERSONAL_SCHEMA;

    const formRef = useRef<any>();
    const [formError, setFormError] = useState<any>({});
    const [formValue, setFormValue] = useState<PERSONALMODEL>(PERSONALFORM);

    const onValueChange = (data: any) => {
        setFormValue(data);
    }

    const stepNext = () => {
        if (!formRef.current.check()) {
            return;
        }
        setStep(1);
        onChange(formValue, 'personal');
    }

    useEffect(() => {
        console.log(formData);
        setFormValue(formData)
    }, [formData])

    return (
        <div className="w-full sm:w-3/4" >
            <Form fluid
                ref={formRef}
                checkTrigger="none"
                onChange={onValueChange}
                onCheck={setFormError}
                formValue={formValue}
                model={schema}
            >
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                    <Form.Group controlId="firstName-6">
                        <Form.ControlLabel>Profile Photo</Form.ControlLabel>
                        <Form.Control name="avatar" context="avatar" docType="PD-Employee" folder="Home/Avatar" accepter={FileInputControl} errorMessage={formError.avatar} />
                    </Form.Group>
                    <Form.Group controlId="firstName-6">
                        <Form.ControlLabel>First Name</Form.ControlLabel>
                        <Form.Control name="first_name" errorMessage={formError.first_name} />
                    </Form.Group>
                    <Form.Group controlId="middleName-6">
                        <Form.ControlLabel>Middle Name</Form.ControlLabel>
                        <Form.Control name="middle_name" errorMessage={formError.middle_name} />
                    </Form.Group>
                    <Form.Group controlId="lastName-6">
                        <Form.ControlLabel>Last Name</Form.ControlLabel>
                        <Form.Control name="last_name" errorMessage={formError.last_name} />
                    </Form.Group>
                    <Form.Group controlId="empNumber-6">
                        <Form.ControlLabel>Employee#</Form.ControlLabel>
                        <Form.Control name="employee_number" errorMessage={formError.employee_number} />
                    </Form.Group>
                    <Form.Group controlId="dtBirth-6">
                        <Form.ControlLabel>Date of Birth</Form.ControlLabel>
                        <Form.Control name="date_of_birth" accepter={DatePicker} format="dd-MM-yyyy" oneTap block errorMessage={formError.date_of_birth} />
                    </Form.Group>
                    <Form.Group controlId="gender-6">
                        <Form.ControlLabel>Gender</Form.ControlLabel>
                        <Form.Control name="gender" accepter={InputPicker} data={genderData} errorMessage={formError.gender} block />
                    </Form.Group>
                    <Form.Group controlId="maritalStatus-6">
                        <Form.ControlLabel>Marital Status</Form.ControlLabel>
                        <Form.Control name="marital_status" accepter={InputPicker} data={maritalData} errorMessage={formError.marital_status} block />
                    </Form.Group>
                    <Form.Group controlId="fatherName-6">
                        <Form.ControlLabel>Father Name</Form.ControlLabel>
                        <Form.Control name="father_name" errorMessage={formError.father_name} />
                    </Form.Group>
                    <Form.Group controlId="motherName-6">
                        <Form.ControlLabel>Mother Name</Form.ControlLabel>
                        <Form.Control name="mother_name" errorMessage={formError.mother_name} />
                    </Form.Group>
                    <Form.Group controlId="religion-6">
                        <Form.ControlLabel>Religion</Form.ControlLabel>
                        <Form.Control name="religion" accepter={InputPicker} data={religion} block preventOverflow={true} errorMessage={formError.religion} />
                    </Form.Group>
                    <Form.Group controlId="eduQualification-6">
                        <Form.ControlLabel>Highest Education Qualification</Form.ControlLabel>
                        <Form.Control name="edu_qualification" accepter={InputPicker} data={education} block preventOverflow={true} errorMessage={formError.edu_qualification} />
                    </Form.Group>

                    <Form.Group>
                        <ButtonToolbar>
                            {/* <Button appearance="default">Clear</Button> */}
                            <Button appearance="primary" onClick={stepNext}>Continue <FiChevronRight className='inline-block' /></Button>
                        </ButtonToolbar>
                    </Form.Group>
                </div>
            </Form>
        </div >
    )
}

export default PersonalInfo