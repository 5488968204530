import SearchIcon from '@rsuite/icons/Search';
import { FC, useRef, useState } from 'react';
import { BsChevronExpand } from 'react-icons/bs';
import { TbCheck, TbCirclePlus, TbPinnedOff, TbSettings } from 'react-icons/tb';
import { Input, InputGroup, Popover, Whisper } from 'rsuite';
import { useCompanyContext } from '../utils/CompanyContextProvider';
import AddCompany from './AddCompany';

const CompanyTile: FC<any> = ({ activeMenu }) => {
    const { activeCompany, setActiveCompanyToLS, companies } = useCompanyContext();
    const [openPopup, setOpenPopup] = useState(false);
    const companyList = useRef<any>();

    const companyPopupOpen = () => {
        console.log('Popup open');
        companyList.current.close();
        setOpenPopup(true);
        console.log('🚀 ~ file: CompanyTile.tsx ~ line 12 ~ openPopup', openPopup);
    }

    const handleClose = () => {
        console.log('🚀 ~ file: Dashboard.tsx ~ line 18 ~ handleClose ~ handleClose');
        setOpenPopup(false);
    }

    const CompanyPopover = (
        <Popover arrow={false} className={`${activeMenu ? '!left-56' : '!left-16'} !rounded-lg !w-56 !p-0`}>
            <div className='px-3 pt-3 border-b border-slate-200'>
                <InputGroup inside className='w-full mb-1'>
                    <Input size="sm" />
                    <InputGroup.Addon className='!p-2'>
                        <SearchIcon />
                    </InputGroup.Addon>
                </InputGroup>
                {companies.map((item: any, index: number) =>
                    <div className={`group flex items-center mb-1 justify-between hover:bg-slate-100 p-2 rounded-md cursor-pointer ${activeCompany?.name === item.name && 'bg-slate-100'}`} key={index} onClick={() => setActiveCompanyToLS(item)}>
                        <div className="flex w-4/5">
                            <div className={` items-center justify-center h-6 w-6 text-center rounded-md border border-blue-400 bg-blue-300 text-white cursor-pointer`}>
                                <div>{item.comp_name[0].toUpperCase()}</div>
                            </div>
                            <div className='text-sm px-2 flex-1 whitespace-nowrap overflow-hidden overflow-ellipsis'>{item.comp_name}</div>
                        </div>
                        <div className='flex'>
                            {activeCompany?.name === item.name && <TbCheck size={15} color='var(--rs-green-500)' />}
                            <TbPinnedOff className='ml-2 hidden group-hover:block' size={15} color='var(--rs-gray-500)' />
                        </div>
                    </div>
                )}
            </div>
            <div className='p-3'>
                <div className="flex items-center mb-2">
                    <TbSettings size={20} color='var(--rs-gray-600)' className='mr-2' /> Company Settings
                </div>
                <div className="flex items-center cursor-pointer hover:text-blue-500 group" onClick={companyPopupOpen}>
                    <TbCirclePlus size={20} color='var(--rs-gray-600)' className='mr-2 group-hover:stroke-blue-500' /> Add Company
                </div>
            </div>
        </Popover>
    )

    return (
        <>
            <Whisper
                placement="rightStart"
                trigger="click"
                speaker={CompanyPopover}
                ref={companyList}
            >
                <div className='mx-4 my-4 flex'>
                    <div className='flex items-center justify-center h-10 w-10 rounded-md bg-slate-300 text-slate-600 text-xl font-bold ring-offset-2 ring-2 ring-slate-200 hover:ring-blue-400 cursor-pointer'>
                        {activeCompany?.comp_name[0].toUpperCase()}
                    </div>
                    {activeMenu && <div className='px-2 text-lg font-bold'>{activeCompany?.comp_name}</div>}
                    {activeMenu && <div className='flex flex-1 justify-end' >
                        <BsChevronExpand />
                    </div>
                    }
                </div>
            </Whisper>
            <AddCompany state={openPopup} handleClose={handleClose} />
        </>
    )
}

export default CompanyTile