import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import { MenuContextProvider } from './utils/MenuContextProvider';
import { AuthContextProvider } from './utils/AuthContextProvider';
import { CompanyContextProvider } from './utils/CompanyContextProvider';

ReactDOM.render(
    <React.StrictMode>
        <AuthContextProvider>
            <MenuContextProvider>
                <CompanyContextProvider>
                    <App />
                </CompanyContextProvider>
            </MenuContextProvider>
        </AuthContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

