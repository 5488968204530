import { FiMenu } from 'react-icons/fi'
import { IoCloseSharp, IoNotificationsOutline } from 'react-icons/io5'
import { RiMoonLine } from 'react-icons/ri'
import { useMenuContext } from '../utils/MenuContextProvider'
import Avatar from './Avatar'

const Header = () => {
    const { setActiveMenu, activeMenuItem, activeMenu } = useMenuContext();
    return (
        <div className="shadow-sm z-10 bg-white px-3 py-6 sm:py-2 grid grid-cols-2 justify-between items-center sticky top-0">
            <div className='flex'>
                <div className='hidden md:block'>
                    {activeMenu
                        ? <IoCloseSharp className="w-6 h-6 cursor-pointer hover:text-blue-400 inline" onClick={() => { setActiveMenu((prev: any) => !prev) }} />
                        : <FiMenu className="w-6 h-6 cursor-pointer hover:text-blue-400 inline" onClick={() => { setActiveMenu((prev: any) => !prev) }} />}
                </div>

                <div className='px-3'>{activeMenuItem}</div>
            </div>
            <div className='flex justify-self-end items-center'>
                {/* <RiMoonLine /> */}
                <Avatar />
            </div>
        </div>
    )
}

export default Header