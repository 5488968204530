import axios from 'axios';
import { head } from 'lodash';

export const getEmployees = async () => {
    try {
        const response = await axios.get('/api/resource/PD-Employee?fields=["name","first_name","last_name","designation","employee_number", "avatar"]');
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const getEmployee = async (id: any) => {
    try {
        const response = await axios.get(`/api/resource/PD-Employee/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const putEmployee = async (data: any) => {
    try {
        const response = await axios.post('/api/resource/PD-Employee', data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const uploadAvatar = async (data: any) => {
    try {
        const response = await axios.post('/api/method/upload_file', data, { headers: { 'Content-Type': 'multipart/form-data' } });
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const getEmployeeDocs = async (id: any) => {
    try {
        const response = await axios.get(`/api/resource/PD-UserDoc?fields=["*"]&filters=[["belongs_to", "=", "${id}"]]`);
        return response.data;
    } catch (error) {
        console.error(error);
    }   
}

export const postEmployeeDocs = async (data: any) => {
    try {
        const response = await axios.post('/api/resource/PD-UserDoc', data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}