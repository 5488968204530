import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import { useEffect, useState } from 'react';
import { BsFileEarmarkBreakFill, BsFileEarmarkExcelFill, BsFillFileEarmarkFill } from 'react-icons/bs';
import { useMatch } from 'react-router-dom';
import { Breadcrumb, Nav } from 'rsuite';
import background from "../assets/images/blob-scene-haikei.svg";
import defaultUser from '../assets/images/defaultuser.png';
import { EmpDocuments, ErrorChip } from '../components';
import { CONTACT_PROPS, OFFICIAL_PROPS, PERSONAL_PROPS } from '../constants/DETAILS_PROPS';
import { ProfileDataContainer } from '../containers';
import { getEmployee } from '../services/Employees';
import { NavLink } from '../utils/NavLink';
import Loader from '../components/Loader';

const EmployeeDetails = () => {
    const match = useMatch('employees/details/:id');
    const [empData, setEmpData] = useState<any>({});
    const [active, setActive] = useState('docs');
    const [isLoading, setIsLoading] = useState(true);
    const personalProps = PERSONAL_PROPS;
    const contactsProps = CONTACT_PROPS;
    const officialProps = OFFICIAL_PROPS;

    useEffect(() => {
        const getEmployeeByID = async () => {
            const ID = match?.params.id || '';
            const response = await getEmployee(ID);
            setEmpData(response.data);
            setIsLoading(false);
        }
        getEmployeeByID();
    }, [match])


    return (
        <>
            {isLoading
                ? <Loader />
                : <div>
                    <Breadcrumb separator={<AngleRightIcon />} className='mb-3'>
                        <Breadcrumb.Item as={NavLink} href="/employees">Employees</Breadcrumb.Item>
                        <Breadcrumb.Item active>Profile</Breadcrumb.Item>
                    </Breadcrumb>

                    <div className='bg-white shadow-sm rounded-lg p-4 flex justify-center' style={{ backgroundImage: `url(${background})`, backgroundBlendMode: 'soft-light', backgroundColor: '#8bb2f145', backgroundSize: 'cover' }}>
                        <div className='lg:flex'>
                            <div className='flex lg:mr-5 justify-center lg:border-r border-r-blue-200 self-center'>
                                <div className="flex h-24 w-24">
                                    {
                                        empData?.avatar
                                            ? <img src={empData?.avatar} onError={(e: any) => { e.target.src = defaultUser }} alt="" className='rounded-full border-2 p-0.5 border-solid border-blue-500 w-24 h-24' />
                                            : <img src={defaultUser} alt="default_avatar" className='rounded-full border border-solid border-gray-300 object-cover h-full w-full' />
                                    }
                                </div>

                                <div className='self-center px-5'>
                                    <div className='text-lg font-semibold'>{empData?.first_name} {empData?.middle_name} {empData?.last_name}</div>
                                    <div className='text-xs text-gray-400'>Staff # {empData?.employee_number}</div>
                                    <div className='text-sm text-gray-500'>{empData?.designation}</div>
                                </div>
                            </div>
                            <div className='grid grid-cols-3 gap-4 my-2'>
                                <div className='bg-white p-3 rounded-lg flex'>
                                    <div className='hidden md:block bg-blue-100 p-3 rounded-md'>
                                        <BsFillFileEarmarkFill size={30} color='#0a5dc2' />
                                    </div>
                                    <div className='md:pl-4 md:pr-2 text-left'>
                                        <div>Total Documents</div>
                                        <div className='text-3xl font-bold'>12</div>
                                    </div>
                                </div>
                                <div className='bg-white p-3 rounded-lg flex'>
                                    <div className='hidden md:block bg-red-100 p-3 rounded-md'>
                                        <BsFileEarmarkExcelFill size={30} color='#eb3626' />
                                    </div>
                                    <div className='md:pl-4 md:pr-2 text-left'>
                                        <div>Expired Documents</div>
                                        <div className='text-3xl font-bold'>3</div>
                                    </div>
                                </div>
                                <div className='bg-white p-3 rounded-lg flex'>
                                    <div className='hidden md:block bg-green-100 p-3 rounded-md'>
                                        <BsFileEarmarkBreakFill size={30} color='#37ab3c' />
                                    </div>
                                    <div className='md:pl-4 md:pr-2 text-left'>
                                        <div>Processing Documents</div>
                                        <div className='text-3xl font-bold'>2</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ErrorChip />
                    <div className='bg-white rounded-lg mt-4 p-4'>
                        <Nav appearance='subtle' activeKey={active} onSelect={setActive}>
                            <Nav.Item eventKey="personal">Personal</Nav.Item>
                            <Nav.Item eventKey="contacts">Contacts</Nav.Item>
                            <Nav.Item eventKey="official">Official</Nav.Item>
                            <Nav.Item eventKey="docs">Documents</Nav.Item>
                            {/* <Nav.Item eventKey="process">Document Process</Nav.Item> */}
                        </Nav>
                        {active === 'personal' && <ProfileDataContainer empData={empData} title='Personal Details' detailsProps={personalProps} />}
                        {active === 'contacts' && <ProfileDataContainer empData={empData} title='Contact Details' detailsProps={contactsProps} />}
                        {active === 'official' && <ProfileDataContainer empData={empData} title='Official Details' detailsProps={officialProps} />}
                        {active === 'docs' && <EmpDocuments />}
                    </div>
                </div>
            }
        </>
    )

}

export default EmployeeDetails