import { differenceInDays } from 'date-fns';
import moment from 'moment';
import { Schema } from 'rsuite';
const { StringType, DateType, ArrayType, ObjectType, MixedType } = Schema.Types;

export const PERSONAL_SCHEMA = Schema.Model({
    first_name: StringType().isRequired('Required.'),
    last_name: StringType().isRequired('Required.'),
    employee_number: StringType().isRequired('Required.'),
    date_of_birth: DateType().isRequired('Required.'),
    gender: StringType().isRequired('Required.'),
    marital_status: StringType().isRequired('Required.'),
    father_name: StringType().isRequired('Required.'),
    mother_name: StringType().isRequired('Required.'),
    religion: StringType().isRequired('Required.'),
    edu_qualification: StringType().isRequired('Required.'),
    avatar: ObjectType().addRule((value: any, data: any) => {
        const fileData = value.get('file');
        if (fileData.size > 2 * 1024 * 1024) {
            return false;
        }
        return true;
    }, 'File size should be less than 2 MB')
});

export const CONTACTS_SCHEMA = Schema.Model({
    mobile_number: StringType().isRequired('Required.').pattern(/^(?:971)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/, 'Invalid Mobile Number'),
    alt_mobile_number: StringType().isRequired('Required.').pattern(/^(?:971)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/, 'Invalid Mobile Number'),
    email: StringType().isEmail('Enter valid email address'),
    present_address: StringType().isRequired('Required.'),
    permanent_address: StringType().isRequired('Required.')
});

export const OFFICIAL_SCHEMA = Schema.Model({
    date_of_joining: DateType().isRequired('Required.'),
    job_type: StringType().isRequired('Required.'),
    department: StringType().isRequired('Required.'),
    grade: StringType().isRequired('Required.'),
    designation: StringType().isRequired('Required.'),
    work_site: StringType().isRequired('Required.'),
});

export const DOCUMENT_ARRAY_SCHEMA = Schema.Model({
    documents: ArrayType().of(
        ObjectType().shape({
            docType: StringType().isRequired('This field is required.'),
            docName: StringType().isRequired('This field is required.'),
            docID: StringType().isRequired('This field is required.'),
            dtIssued: DateType().isRequired('This field is required.'),
            dtExpiry: DateType()
                .isRequired('This field is required.')
                .addRule((value, data) => {
                    if (data.dtExpiry !== null) {
                        const dtExpiry = moment(value, 'YYYY-MM-DD').toDate();
                        const dtIssued = moment(data.dtIssued, 'YYYY-MM-DD').toDate();
                        let dtDiff = differenceInDays(dtIssued, dtExpiry);
                        console.log('🚀 ~ file: DocumentEntry.tsx ~ line 23 ~ .addRule ~ dtDiff', dtDiff);
                        return dtDiff > 0 ? false : true;
                    }
                    return true;
                }, 'Issued date greater than expiry date')
        })
    )
});

export const DOCUMENT_SCHEMA = Schema.Model({
    documents: ObjectType().shape({
        document_id: StringType().isRequired('This field is required.'),
        document_type: StringType().isRequired('This field is required.'),
        document_name: StringType().isRequired('This field is required.'),
        issued_date: DateType().isRequired('This field is required.'),
        expiry_date: DateType()
            .isRequired('This field is required.')
            .addRule((value, data) => {
                if (data.dtExpiry !== null) {
                    const dtExpiry = moment(value, 'YYYY-MM-DD').toDate();
                    const dtIssued = moment(data.dtIssued, 'YYYY-MM-DD').toDate();
                    let dtDiff = differenceInDays(dtIssued, dtExpiry);
                    console.log('🚀 ~ file: DocumentEntry.tsx ~ line 23 ~ .addRule ~ dtDiff', dtDiff);
                    return dtDiff > 0 ? false : true;
                }
                return true;
            }, 'Issued date greater than expiry date')
    })
});