export const JOBTYPE = ['Permanent', 'Temporary', 'Contractor', 'Sub Contractor'].map(item => ({
    label: item,
    value: item
}));

export const DEPARTMENTS = ['HR', 'Maintanance', 'IT', 'Manpower'].map(item => ({
    label: item,
    value: item
}));

export const DESIGNATIONS = ['Technician', 'Cleaner', 'Driver', 'Supervisor', 'Manager'].map(item => ({
    label: item,
    value: item
}));