import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonToolbar, Form } from 'rsuite';
import { DOCFROM_INITIAL } from '../constants/INITIAL_STATES';
import { DOCUMENT_ARRAY_SCHEMA } from '../helpers/Validators';
import DocumentInputControl from './DocumentInputControl';

const DocumentEntry = () => {
    const validator = DOCUMENT_ARRAY_SCHEMA;
    const [formError, setFormError] = useState<any>({});
    const formRef = useRef<any>(null);
    const [formValue, setFormValue] = useState<any>({ documents: DOCFROM_INITIAL });

    const onSubmit = () => {
        formRef.current.check();
        console.log('🚀 ~ file: DocumentEntry.tsx ~ line 43 ~ onSubmit ~ formError', formError);
        if (formError.documents.hasError) {
            console.log('Dont submit data');
        } else {
            console.log('Submit data');
        }
    }

    return (
        <div className="w-full">
            <Form
                model={validator}
                onCheck={setFormError}
                ref={formRef}
                checkTrigger="none"
                onChange={setFormValue}
                formValue={formValue}
            >
                <Form.Control
                    name="documents"
                    accepter={DocumentInputControl}
                    fieldError={formError.documents}
                />
                <div className="flex justify-end">
                    <Form.Group >
                        <ButtonToolbar>
                            <Link to='/employees'>
                                <Button appearance="default" type="submit">Skip & Continue</Button>
                            </Link>
                            <Button appearance="primary" type="submit" onClick={onSubmit}>Submit</Button>
                        </ButtonToolbar>
                    </Form.Group>
                </div>
            </Form>
        </div >
    )
}

export default DocumentEntry