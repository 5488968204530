import React from 'react'
import { IconButton } from 'rsuite'
import { TbPencil } from "react-icons/tb";

const ProfileDataContainer = ({ empData, title, detailsProps }: any) => {
    return (
        <div>
            <div className='text-lg pt-1 flex justify-end'>
                {/* {title} */}
                <IconButton className='ml-3' icon={<TbPencil color='#272727' size={20} />} circle size="sm" />
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                {
                    detailsProps.map(({ label, key }: any) =>
                        <div key={key}>
                            <div className='text-xs font-medium text-gray-500'>{label}</div>
                            <div className='text-base text-blue-600'>{empData?.[key]}</div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default ProfileDataContainer