export const DOCTYPE = ['Visa', 'Passport', 'Emirates ID', 'Health ID'].map(item => ({
    label: item,
    value: item
}));

export const DOCUMENTCOLUMNS = [
    {
        key: 'document_id',
        label: 'Document ID',
        flexGrow: 1,
        minWidth: 100,
    },
    {
        key: 'document_type',
        label: 'Document Type',
        flexGrow: 1,
        minWidth: 100,
        fixed: true
    },
    {
        key: 'document_name',
        label: 'Document Name',
        flexGrow: 1,
        minWidth: 100

    },
    {
        key: 'issued_date',
        label: 'Issued Date',
        flexGrow: 1,
        minWidth: 100

    },
    {
        key: 'expiry_date',
        label: 'Expire Date',
        flexGrow: 1,
        minWidth: 100

    }
]