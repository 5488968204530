
import { RiGroupFill, RiGroupLine, RiPieChartFill, RiPieChartLine, RiSettings4Fill, RiSettings4Line, RiUserSettingsFill, RiUserSettingsLine } from 'react-icons/ri';
import { HiDocumentDuplicate, HiOutlineDocumentDuplicate } from 'react-icons/hi';

export const MENU_ITEMS = [

    {
        path: "/",
        label: 'Dashboard',
        icon: RiPieChartLine,
        iconSolid: RiPieChartFill,
    },
    {
        path: '/employees',
        label: 'Employees',
        icon: RiGroupLine,
        iconSolid: RiGroupFill,
    },
    {
        path: '/documents',
        label: 'Documents',
        icon: HiOutlineDocumentDuplicate,
        iconSolid: HiDocumentDuplicate,
    },
    {
        path: "",
        label: 'User Management',
        icon: RiUserSettingsLine,
        iconSolid: RiUserSettingsFill,
    },
    {
        path: "",
        label: 'Settings',
        icon: RiSettings4Line,
        iconSolid: RiSettings4Fill,
    }
];