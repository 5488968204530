import { ThreeDots } from 'react-loader-spinner';

const ReactLoader = () => {
    return (
        <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2'>
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#3498ff"
                ariaLabel="three-dots-loading"
                visible={true}
            />
        </div>
    );
}

export default ReactLoader;