import { FC, useEffect, useState } from 'react';
import { BiCloudUpload } from 'react-icons/bi';
import { Button, Form, Modal } from 'rsuite';
import { postCompany } from '../services/Company';
import { useCompanyContext } from '../utils/CompanyContextProvider';

const AddCompany: FC<{ state?: boolean, handleClose?: () => void }> = ({ state, handleClose }) => {
    const [openPopup, setOpenPopup] = useState<boolean>(false);
    const { companies, isLoading } = useCompanyContext();
    const [values, setValues] = useState<any>();

    useEffect(() => {
        console.log('🚀 ~ file: AddCompany.tsx ~ line 14 ~ useEffect ~ companies.length', companies.length);
        if (companies.length < 1 && !isLoading) {
            console.log('Length ');
            
            setOpenPopup(true);
        }
    }, []);

    useEffect(() => {
        console.log('State Changed', state);
        if (state !== undefined) setOpenPopup(state);
    }, [state])


    const handleChange = (name: string, event: any) => {
        setValues({ ...values, [name]: event });
    }

    const onSubmit = () => {
        postCompany(values);
    }

    return (
        <Modal autoFocus={true} backdrop='static' keyboard={false} open={openPopup} onClose={handleClose} >
            <Modal.Header>
                <Modal.Title>Add Company</Modal.Title>
                <div className='text-xs text-slate-400 font-light mt-4'>Employees will be tagged to any of the companies. If you want to add individuals you can create a dummy company and add all the individuals under that.</div>
                <div>State: {openPopup}</div>
            </Modal.Header>
            <Form fluid>
                <Modal.Body className='px-1'>
                    <Form.Group controlId="companyName">
                        <Form.ControlLabel>Logo</Form.ControlLabel>
                        <input type="file" id="upload" hidden onChange={($event) => handleChange('logo', $event.target.files)} />
                        <label htmlFor="upload" className='inline-block bg-gray-200 rounded-md px-3 py-2 cursor-pointer text-blue-600'>
                            <BiCloudUpload size={50} />
                        </label>
                    </Form.Group>
                    <Form.Group controlId="companyName">
                        <Form.ControlLabel>Company Name</Form.ControlLabel>
                        <Form.Control name="comp_name" onChange={(value: string) => handleChange('comp_name', value)} />
                    </Form.Group>
                    <Form.Group controlId="emailAddress">
                        <Form.ControlLabel>Email Address</Form.ControlLabel>
                        <Form.Control name="email" onChange={(value: string) => handleChange('email', value)} />
                    </Form.Group>
                    <Form.Group controlId="phone">
                        <Form.ControlLabel>Phone</Form.ControlLabel>
                        <Form.Control name="phone" onChange={(value: string) => handleChange('phone', value)} />
                    </Form.Group>
                    <Form.Group controlId="address">
                        <Form.ControlLabel>Address</Form.ControlLabel>
                        <Form.Control name="address" onChange={(value: string) => handleChange('address', value)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type='submit' appearance="primary" onClick={onSubmit}>Ok</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
export default AddCompany