import { useEffect, useState } from 'react';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { IoChatbubbleEllipsesOutline, IoHelpBuoyOutline, IoLogOutOutline, IoPersonCircleOutline } from 'react-icons/io5';
import { Avatar as RSAvatar, Popover, Whisper } from 'rsuite';
import { useAuth } from '../utils/AuthContextProvider';

const Avatar = () => {
    const user = useAuth()
    const userData = user?.user;
    const [initial, setInitial] = useState<string>('');

    useEffect(() => {
        const nameArray: string[] = userData.full_name.split(' ') //userData.full_name.split(' ')[1][0]
        if (nameArray.length >= 2) {
            setInitial(nameArray[0][0] + nameArray[nameArray.length - 1][0]);
        } else {
            setInitial(nameArray[0][0])
        }
    }, [userData])


    const renderAvatar = (
        <Popover arrow={false} className='!rounded-md !p-0'>
            <div className='text-base mb-1 p-3 border-b'>
                <div className="flex">
                    <div className='h-10'>
                        <RSAvatar src={`http://admin.flumics.com${userData.user_image}`} circle alt={initial}></RSAvatar>
                    </div>
                    <div className='ml-2'>
                        <div className='font-medium leading-4'>{userData.full_name}</div>
                        <div className='text-xs text-slate-400'>{userData.user_id}</div>
                    </div>
                </div>
            </div>
            <div className='my-2 px-2'>
                <div className='text-sm flex items-center text-slate-400 hover:bg-slate-100 hover:text-blue-500 rounded px-1 py-2 cursor-pointer'><HiOutlineUserCircle size={19} className='mr-2' /> Profile</div>
                <div className='text-sm flex items-center text-slate-400 hover:bg-slate-100 hover:text-blue-500 rounded px-1 py-2 cursor-pointer'><IoHelpBuoyOutline size={20} className='mr-2' /> Help Center</div>
                <div className='text-sm flex items-center text-slate-400 hover:bg-slate-100 hover:text-blue-500 rounded px-1 py-2 cursor-pointer'><IoChatbubbleEllipsesOutline size={19} className='mr-2' /> Request a Feature</div>
                <div className='text-sm flex items-center text-slate-400 hover:bg-slate-100 hover:text-blue-500 rounded px-1 py-2 cursor-pointer' onClick={() => handleSelect('logout')}><IoLogOutOutline size={19} className='mr-2' /> Logout</div>
            </div>
        </Popover>
    )

    const handleSelect = (tag: string) => {
        switch (tag) {
            case 'profile': break;
            case 'logout': user?.logOut()
                break;
            default:
                break;
        }

    }


    return (
        // <Dropdown placement="bottomEnd" renderToggle={renderAvatar} menuStyle={{ width: '150px' }} onSelect={handleSelect}>
        //     <Dropdown.Item eventKey={'profile'}>Profile</Dropdown.Item>
        //     <Dropdown.Item eventKey={'logout'}>Logout</Dropdown.Item>
        // </Dropdown>
        <Whisper
            placement="bottomEnd"
            trigger="click"
            speaker={renderAvatar}
        >
            <RSAvatar className='cursor-pointer hover:ring-2 hover:ring-offset-2 hover:ring-blue-300' size="sm" src={`http://admin.flumics.com${userData.user_image}`} circle alt={initial}></RSAvatar>

        </Whisper>
    )
}

export default Avatar