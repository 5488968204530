import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'rsuite/dist/rsuite.min.css';
import ReactLoader from './components/Loader';
import { Home } from './containers';
import { AddDocument, AddEmployee, Dashboard, Documents, EmployeeDetails, Employees } from './pages';
import Login from './pages/Login';
import { GuardedRoutes } from './utils/GuardedRoutes';
import { PublicRoutes } from './utils/PublicRoutes';

const App = () => {
    return (
        <div>
            <BrowserRouter>
                    <Suspense fallback={<ReactLoader />}>
                        <Routes>
                            <Route path='/' element={<GuardedRoutes />}>
                                <Route path='/' element={<Home />}>
                                    <Route path='/' element={<Dashboard />}></Route>
                                    <Route path='/dashboard' element={<Dashboard />}></Route>
                                    <Route path='/employees'>
                                        <Route path='' element={<Employees />}></Route>
                                        <Route path='add' element={<AddEmployee />}></Route>
                                        <Route path='details/:id' element={<EmployeeDetails />}></Route>
                                    </Route>
                                    <Route path='/documents'>
                                        <Route path='' element={<Documents />}></Route>
                                        <Route path='add' element={<AddDocument />}></Route>
                                    </Route>
                                </Route>
                            </Route>
                            <Route path='/login' element={<PublicRoutes />}>
                                <Route path='' element={<Login />} ></Route>
                            </Route>
                        </Routes>
                    </Suspense>
            </BrowserRouter>
        </div>
    )
}
export default App
