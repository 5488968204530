import { differenceInDays } from 'date-fns';
import { useState } from 'react';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import { BiCheckCircle, BiCloudUpload } from 'react-icons/bi';
import { ButtonToolbar, DatePicker, IconButton, Input, InputPicker, Tag } from 'rsuite';
import { DOCTYPE } from '../constants/DOCUMENTS';
import { DOCFROM_INITIAL } from '../constants/INITIAL_STATES';

const DocumentInputControl = ({ value = [], onChange, fieldError }: any) => {
    const documentData = DOCTYPE;
    const initialState = DOCFROM_INITIAL;

    const errors = fieldError ? fieldError.array : [];
    const [documents, setDocuments] = useState(value);
    const handleChangeDocuments = (nextDocument: any) => {
        setDocuments(nextDocument);
        onChange(nextDocument);
    };
    const onValueChange = (index: number, name: string, value: any) => {
        let myNewArray;
        if (name === 'dtExpiry' && value !== null) {
            const dateDiff = differenceInDays(value, new Date());
            const status = isNaN(dateDiff) ? 'none' : dateDiff < 0 ? 'expired' : 'active'

            myNewArray = Object.assign([...documents], {
                [index]: {
                    ...documents[index],
                    [name]: value,
                    status
                }
            });
        } else {
            myNewArray = Object.assign([...documents], {
                [index]: {
                    ...documents[index],
                    [name]: value
                }
            });
        }
        handleChangeDocuments(myNewArray);
    };

    const increment = (index: any) => {
        handleChangeDocuments([...documents, initialState[0]])
    }

    const decrement = (index: number) => {
        handleChangeDocuments([...documents.slice(0, index), ...documents.slice(index + 1)]);
    }

    return (
        <table className='bg-white rounded-md w-full border-separate border-spacing-3 mb-5'>
            <thead className='text-left'>
                <tr>
                    <th>Document Type</th>
                    <th>Document Name</th>
                    <th>Document ID</th>
                    <th>Issued Date</th>
                    <th>Expiry Date</th>
                    <th>File</th>
                    <th>Status</th>
                    <th>Add/Remove</th>
                </tr>
            </thead>
            <tbody>
                {
                    documents.map((count: any, index: number) =>
                        <tr key={index}>
                            <td className='align-top'>
                                <InputPicker placeholder="Document Type" value={documents[index].docType} data={documentData} block name="docType" onChange={(value: any) => onValueChange(index, 'docType', value)} />
                                <span className='text-xs text-red-600'>{errors[index] ? errors[index].object.docType.errorMessage : null}</span>
                            </td>
                            <td className='align-top'>
                                <Input placeholder="Document Name" value={documents[index].docName} name="docName" onChange={(value, event) => onValueChange(index, 'docName', event.target.value)} />
                                <span className='text-xs text-red-600'>{errors[index] ? errors[index].object.docName.errorMessage : null}</span>
                            </td>
                            <td className='align-top'>
                                <Input placeholder="Document ID" value={documents[index].docID} name="docID" onChange={(value, event) => onValueChange(index, 'docID', event.target.value)} />
                                <span className='text-xs text-red-600'>{errors[index] ? errors[index].object.docID.errorMessage : null}</span>
                            </td>
                            <td className='align-top'>
                                <DatePicker format="dd-MM-yyyy" value={documents[index].dtIssued} name="dtIssued" onChange={(date) => onValueChange(index, 'dtIssued', date)} block oneTap />
                                <span className='text-xs text-red-600'>{errors[index] ? errors[index].object.dtIssued.errorMessage : null}</span>
                            </td>
                            <td width='20%' className='align-top'>
                                <DatePicker format="dd-MM-yyyy" value={documents[index].dtExpiry} name="dtExpiry" onChange={(date) => { onValueChange(index, 'dtExpiry', date) }} oneTap block />
                                <span className='text-xs text-red-600'>{errors[index] ? errors[index].object.dtExpiry.errorMessage : null}</span>
                            </td>
                            <td width='13%' className='align-top'>
                                <input type="file" id="upload" onChange={(event) => onValueChange(index, 'file', event.target.files)} hidden />
                                <label htmlFor="upload" className='inline-block bg-gray-200 rounded px-3 py-2 cursor-pointer text-blue-600'>
                                    {documents[index].file === null
                                        ? <span><BiCloudUpload className='inline-block' size={16} /> Choose file</span>
                                        : <span><BiCheckCircle className='inline-block' size={16} /> File Selected</span>}
                                </label>
                            </td>
                            <td className='align-top'>
                                <Tag color={
                                    documents[index].status === 'expired'
                                        ? 'red'
                                        : documents[index].status === 'active' ? 'green' : 'blue'}>
                                    {documents[index].status.toUpperCase() || 'None'}
                                </Tag>
                            </td>
                            <td className='align-top text-center'>
                                <ButtonToolbar>
                                    {index < documents.length - 1 && <IconButton icon={<AiFillMinusCircle color='#f44336' size={25} />} circle size="lg" onClick={() => decrement(index)} />}
                                    {index === documents.length - 1 && <IconButton icon={<AiFillPlusCircle color='#4caf50' size={25} />} circle size="lg" onClick={() => increment(index)} />}
                                </ButtonToolbar>
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </table>
    )
}
export default DocumentInputControl