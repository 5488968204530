import { format, parse } from 'date-fns';
import { isEmpty } from 'lodash';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { BiCloudUpload } from 'react-icons/bi';
import { useMatch } from 'react-router-dom';
import { Button, DatePicker, Form, Modal, Uploader } from 'rsuite';
import { DOCUMENTFORM, FILELIST } from '../constants/INITIAL_STATES';
import { DOCUMENT_SCHEMA } from '../helpers/Validators';
import { DocumentModel } from '../models/EMP_DOCS';
import { postEmployeeDocs } from '../services/Employees';

const AddEditPopup = ({ open, handleClose, data }: any) => {

    const formRef = useRef<any>();
    const [formError, setFormError] = useState<any>({});
    const [formValue, setFormValue] = useState<DocumentModel>(DOCUMENTFORM);
    const [fileList, setFileList] = useState<any>([FILELIST])
    const schema = DOCUMENT_SCHEMA;
    const uploaderref = useRef<any>();
    const match = useMatch('employees/details/:id');

    useEffect(() => {
        console.log("🚀 ~ file: AddEditPopup.tsx:25 ~ useEffect ~ isEmpty(data):", isEmpty(data))
        if (!isEmpty(data)) {
            const updateData = { ...data, issued_date: parse(data.issued_date, "yyyy-MM-dd", new Date()), expiry_date: parse(data.expiry_date, "yyyy-MM-dd", new Date()) };
            console.log("🚀 ~ file: AddEditPopup.tsx:25 ~ useEffect ~ updateData:", updateData)
            setFormValue(updateData);
            const updatedfileList = [
                {
                    url: updateData.attachment,
                    name: `${updateData.document_name}.${updateData.attachment.split(".").pop()}`
                }]
            setFileList(updatedfileList);
        } else {
            console.log("Reset form")
            setFormValue(DOCUMENTFORM);
            setFileList([FILELIST])
        }
        console.log("Files", fileList);
    }, [data])

    const onValueChange = (data: any) => {
        setFormValue(data);
    }

    const handleSubmit = () => {
        if (uploaderref.current) {
            uploaderref.current.start();
        }
    }

    const onUploadSuccess = async (data: any) => {
        const ID = match?.params.id;
        const payload = {
            ...formValue,
            attachment: data.message.file_url,
            belongs_to: ID,
            issued_date: format(formValue.issued_date, 'yyyy-MM-dd'),
            expiry_date: format(formValue.expiry_date, 'yyyy-MM-dd'),
        };
        const response = await postEmployeeDocs(payload);
        console.log("🚀 ~ file: AddEditPopup.tsx:43 ~ onUploadSuccess ~ response:", response);
        closeModal();
    }

    const closeModal = () => {
        handleClose();
    }

    return (

        <Modal overflow={true} backdrop="static" role="alertdialog" open={open} onClose={handleClose} size="xs">
            <Modal.Header>{isEmpty(data) ? 'Add' : 'Edit'}</Modal.Header>
            <Modal.Body>
                <Form
                    fluid
                    className='px-4'
                    ref={formRef}
                    checkTrigger="none"
                    onChange={onValueChange}
                    onCheck={setFormError}
                    formValue={formValue}
                    model={schema}>
                    <Form.Group controlId="docID">
                        <Form.ControlLabel>Document ID</Form.ControlLabel>
                        <Form.Control name="document_id" errorMessage={formError.document_id} />
                    </Form.Group>
                    <Form.Group controlId="docType">
                        <Form.ControlLabel>Document Type</Form.ControlLabel>
                        <Form.Control name="document_type" errorMessage={formError.document_type} />
                    </Form.Group>
                    <Form.Group controlId="docName">
                        <Form.ControlLabel>Document Name</Form.ControlLabel>
                        <Form.Control name="document_name" errorMessage={formError.document_name} />
                    </Form.Group>
                    <Form.Group controlId="dtIssued">
                        <Form.ControlLabel>Issued Date</Form.ControlLabel>
                        <Form.Control name="issued_date" accepter={DatePicker} format="dd-MM-yyyy" errorMessage={formError.issued_date} oneTap block />
                    </Form.Group>
                    <Form.Group controlId="dtExpiry">
                        <Form.ControlLabel>Expiry Date</Form.ControlLabel>
                        <Form.Control name="expiry_date" accepter={DatePicker} format="dd-MM-yyyy" errorMessage={formError.expiry_date} oneTap block />
                    </Form.Group>
                    {/* <Form.Group controlId="attachment">
                        <Form.ControlLabel>Attachment</Form.ControlLabel>
                        <Form.Control name="attachment" context="empdoc" docType="PD-UserDoc" folder="Home/EmployeeDocuments" accepter={FileInputControl} errorMessage={formError.attachment} />
                    </Form.Group> */}

                    {/* ! Can be used for multiple file upload (v2) */}

                    <Form.Group controlId="attachment">
                        <Form.ControlLabel>Attachment</Form.ControlLabel>
                        <Form.Control name="file"
                            accepter={Uploader}
                            defaultFileList={fileList}
                            multiple={false}
                            data={{ folder: "Home/EmployeeDocuments", doctype: "PD-UserDoc", is_private: true }}
                            renderFileInfo={(file:any, fileElement:any) => {
                                return (
                                    <>
                                        <span>File Name: {file.name}</span>
                                        <p>File URL: {file.url}</p>
                                    </>
                                );
                            }}
                            autoUpload={false} action="/api/method/upload_file"
                            onSuccess={onUploadSuccess}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose} appearance="subtle" name='cancel'>Cancel</Button>
                <Button onClick={handleSubmit} appearance="primary" name='ok'>{isEmpty(data) ? 'Submit' : 'Update'}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddEditPopup

// TODO: This can be used for multi file upload feature (v2)
const CustomUploader = forwardRef((props: any, ref: any) => {
    console.log("🚀 ~ file: AddEditPopup.tsx:143 ~ CustomUploader ~ props:", props)
    return (
        <Uploader {...props} ref={props.uploaderref} disabled={props.value && props.value.length >= 1}>
            <div>
                <div className={`flex items-center gap-2 bg-gray-200 rounded px-1 w-full py-1 text-blue-600 ${props.value && props.value.length >= 1 ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                    <div className='flex items-center gap-1 bg-blue-500 py-1 px-2 text-white rounded'><BiCloudUpload className='inline-block' size={16} /> Browse file</div>
                    <div className="flex-1 whitespace-nowrap overflow-hidden overflow-ellipsis">
                        {
                            props.value
                                ? props.value.length + ' files selected'
                                : 'No file selected'
                        }
                    </div>

                </div>
            </div>
        </Uploader >
    );
});
