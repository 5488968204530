import React, { useEffect, useState } from 'react'
import background from "../assets/images/login-bg.svg";
import bgImage from "../assets/images/login-bg.png";
import { Button } from 'rsuite';
import { APP_CONSTANTS } from '../constants/APP_CONSTANTS';
import { useAuth } from '../utils/AuthContextProvider';

const Login = () => {
    const auth = useAuth();

    const [values, setValues] = useState<{ usr: string, pwd: string }>({ usr: '', pwd: '' });
    const [error, setError] = useState<{ error: boolean, message: string }>({ error: false, message: '' })
    const [isLoading, setIsLoading] = useState(false)

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
    }

    const onSubmit = async (event: any) => {
        setIsLoading(true);
        event.preventDefault();
        try {
            await auth?.logIn(values);
        } catch {
            setError({ error: true, message: 'Failed to login' })
        }
        setIsLoading(false);
    }

    return (
        <div className='flex bg-slate-100'>
            <div style={{ backgroundImage: `url(${background})`, backgroundColor: '#f3f5f9', backgroundSize: 'cover' }} className='p-28 rounded-tr-3xl rounded-br-3xl text-center col-span-2 w-full h-screen'>
                <div className='text-2xl  text-white'>Take your document management to next level with</div>
                <div className="text-4xl mt-2 text-white font-bold">{APP_CONSTANTS.APPNAME}</div>
                <img src={bgImage} alt="" />
            </div>
            <div className='p-10'>
                <div className='text-lg text-blue-600'>{APP_CONSTANTS.APPNAME}</div>
                <div className='flex items-center justify-center h-full'>
                    <div>
                        <form className='bg-white rounded-lg p-10'>
                            <div className="mb-8">
                                <div className="text-2xl mb-2">Welcome to <strong className='text-blue-600'>{APP_CONSTANTS.APPNAME}</strong></div>
                                <div className="text-[10px] text-slate-400">Embrace the features of prodesk and forget about the hassle of handling documents.</div>
                            </div>
                            <div className="relative w-72 mb-2">
                                <input type="text" value={values.usr} onChange={handleChange} name="usr" id="floating_outlined" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Email Address</label>
                                <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                    </svg>
                                </div>
                            </div>

                            <div className="relative w-72 mb-8">
                                <input type="password" value={values.pwd} onChange={handleChange} name="pwd" id="floating_outlined" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Password</label>
                                <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                    </svg>

                                </div>
                            </div>
                            <Button type="submit" appearance="primary" onClick={onSubmit} block loading={isLoading}>Log In</Button>
                            {error.error && <div className='mt-2 text-sm text-center text-red-400'>Invalid login credentials</div>}
                            <div className='text-center text-sm mt-2'>Forget password? </div>
                        </form>
                        <div className='mt-4 text-slate-400 text-center'>&copy;2022 flumics</div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Login