export const PERSONAL_PROPS = [
    { label: 'First Name', key: 'first_name' },
    { label: 'Middle Name', key: 'middle_name' },
    { label: 'Last Name', key: 'last_name' },
    { label: 'Gender', key: 'gender' },
    { label: 'Date of Birth', key: 'date_of_birth' },
    { label: 'Marital Status', key: 'marital_status' },
    { label: 'Father Name', key: 'father_name' },
    { label: 'Mother Name', key: 'mother_name' },
    { label: 'Religion', key: 'religion' },
    { label: 'Education Qualification', key: 'edu_qualification' }
]

export const CONTACT_PROPS = [
    { label: 'Mobile Number', key: 'mobile_number' },
    { label: 'Alternate Mobile Number', key: 'alt_mobile_number' },
    { label: 'Emergency Contact Number', key: 'emergency_contact' },
    { label: 'Email', key: 'email' },
    { label: 'Present Address', key: 'present_address' },
    { label: 'Permanent Address', key: 'permanent_address' },
]

export const OFFICIAL_PROPS = [
    { label: 'Date of Joining', key: 'date_of_joining' },
    { label: 'Job Type', key: 'job_type' },
    { label: 'Department', key: 'department' },
    { label: 'Grade', key: 'grade' },
    { label: 'Designation', key: 'designation' },
    { label: 'Work Site', key: 'work_site' },
    { label: 'Manager', key: 'reporting_manager' },
];