import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContextProvider';

export const PublicRoutes = () => {
    const auth = useAuth();
    console.log("PublicRoutes",window.location.href);
    return auth?.user
        ? <Navigate to="/" />
        : <Outlet />
};



