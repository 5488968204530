import { Button, Modal } from 'rsuite';

const Popup = ({ open, handleClose }: any) => {

    return (
        <Modal backdrop="static" role="alertdialog" open={open} onClose={handleClose} size="xs">
            <Modal.Body>
                Are you sure want to delete?
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose} appearance="primary" color="red" name='ok'>Delete</Button>
                <Button onClick={handleClose} appearance="subtle" name='cancel'>Cancel</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default Popup