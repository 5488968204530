import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Breadcrumb, Steps } from 'rsuite';
import { ContactDetails, DocumentEntry, OfficialDetails, PersonalInfo } from '../components';
import { putEmployee, uploadAvatar } from '../services/Employees';
import { useCompanyContext } from '../utils/CompanyContextProvider';
import { NavLink } from '../utils/NavLink';

const AddEmployee = () => {

    const [stepIndex, setStepIndex] = useState(0);
    const [formData, setFormData] = useState<any>({});
    const { activeCompany } = useCompanyContext();

    const setStep = (nextStep: number) => {
        console.log("🚀 ~ file: AddEmployee.tsx:17 ~ setStep ~ nextStep:", nextStep)
        setStepIndex(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
    }

    useEffect(() => {
        const postEmployee = async () => {
            if (stepIndex === 3) {
                const avatar = await uploadAvatar(formData.avatar);
                console.log("🚀 ~ file: AddEmployee.tsx:25 ~ postEmployee ~ avatar:", avatar)
                const updatedData = {
                    ...formData,
                    date_of_birth: format(formData.date_of_birth, 'yyyy-MM-dd'),
                    date_of_joining: format(formData.date_of_joining, 'yyyy-MM-dd'),
                    mobile_number: "+" + formData.mobile_number,
                    alt_mobile_number: "+" + formData.alt_mobile_number,
                    emergency_contact: "+" + formData.emergency_contact,
                    belongs_to: activeCompany.comp_name,
                    avatar: avatar.message.file_url
                };
                console.log("🚀 ~ file: AddEmployee.tsx:27 ~ postEmployee ~ updatedData:", updatedData)
                const data = await putEmployee(updatedData);
            }
        }
        postEmployee();
    }, [stepIndex])

    const handleFormChange = async (data: any, context: string) => {
        const newData = { ...formData, ...data }
        setFormData(newData)
        console.log("🚀 ~ file: AddEmployee.tsx:20 ~ handleFormChange ~ newData:", newData)

    }

    const renderElement = (index: number) => {
        switch (index) {
            case 0: return <PersonalInfo setStep={setStep} onChange={handleFormChange} formData={formData} />
            case 1: return <ContactDetails setStep={setStep} onChange={handleFormChange} formData={formData} />
            case 2: return <OfficialDetails setStep={setStep} onChange={handleFormChange} formData={formData} />
            case 3: return <DocumentEntry />
            default:
                break;
        }
    }
    return (
        <div>
            <Breadcrumb separator={<AngleRightIcon />} className='mb-3'>
                <Breadcrumb.Item as={NavLink} href="/employees">Employees</Breadcrumb.Item>
                <Breadcrumb.Item active>Add Employee</Breadcrumb.Item>
            </Breadcrumb>
            <div className="text-lg">Add New Employee</div>
            <Steps current={stepIndex} className='my-3'>
                <Steps.Item title="Personal Information" />
                <Steps.Item title="Contact Details" />
                <Steps.Item title="Official Details" />
                <Steps.Item title="Employee Documents" />
            </Steps>
            {renderElement(stepIndex)}
        </div>
    )
}

export default AddEmployee