import { Outlet } from 'react-router-dom';
import { AddCompany, Header, Sidebar } from '../components';

const Home = () => {
    return (
        <div className="flex flex-row">
            <AddCompany/>
            <Sidebar />
            <div className="w-full" style={{ backgroundColor: '#e4e4e44d' }}>
                <Header />
                <div className="p-5">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default Home