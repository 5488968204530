import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContextProvider';
import Loader from '../components/Loader';

export const GuardedRoutes = () => {
    const auth = useAuth();

    if(auth?.isLoading){
        return <Loader />;
    }

    return auth?.user
        ? <Outlet />
        : <Navigate to="/login" />;
}

