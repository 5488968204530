export const GENDER = ['Male', 'Female', 'Other'].map(item => ({
    label: item,
    value: item
}));

export const MARITAL = ['Single', 'Married', 'Widowed', 'Divorced'].map(item => ({
    label: item,
    value: item
}));

export const EDUCATION = ['No Formal Education', 'Primary', 'Secondary', 'Diploma / Certificates', 'Bachelors', 'Masters / Doctorate'].map(item => ({
    label: item,
    value: item
}));

export const RELIGION = ['Islam', 'Christian', 'Hindu', 'Budhist', 'Jain', 'Sikh', 'Jew', 'Unaffiliated', 'Other', 'Undisclosed'].map(item => ({
    label: item,
    value: item
}));
