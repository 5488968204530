import { forwardRef, useRef, useState, useEffect } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import PhoneInput from 'react-phone-input-2';
import { Button, ButtonToolbar, Form, Input } from 'rsuite';
import { CONTACTFORM } from '../constants/INITIAL_STATES';
import { CONTACTS_SCHEMA } from '../helpers/Validators';
import { CONTACTMODEL } from '../models/BIODATA';
import 'react-phone-input-2/lib/style.css';

const Textarea = forwardRef((props, ref) => <Input {...props} as="textarea" rows={5} />);

const MobileNumberInput = forwardRef((props, ref) => {
    return <PhoneInput
        {...props}
        inputStyle={{ width: '100%' }}
        country='ae'
        disableDropdown={true}
        countryCodeEditable={false}
        defaultMask='.. ... ....'
        prefix='+'
    />
})

const EmergencyNumberInput = forwardRef((props, ref) => {
    return <PhoneInput
        {...props}
        inputStyle={{ width: '100%' }}
        country='ae'
        preferredCountries={['ae', 'in', 'pk', 'bd', 'lk', 'ph', 'my']}
        countryCodeEditable={false}
        masks={{ ae: '.. ... ....' }}
    />
})

const ContactDetails = ({ setStep, onChange, formData }: any) => {
    const schema = CONTACTS_SCHEMA;

    const formRef = useRef<any>();
    const [formError, setFormError] = useState<any>({});
    const [formValue, setFormValue] = useState<CONTACTMODEL>(CONTACTFORM);


    const onValueChange = (data: any) => {
        setFormValue(data);
    }

    const stepNext = () => {
        if (!formRef.current.check()) {
            return;
        }
        setStep(2);
        onChange(formValue, 'contact');
    }

    useEffect(() => {
        setFormValue(formData)
    }, [formData])

    return (
        <div className="w-full sm:w-3/4">
            <Form fluid
                ref={formRef}
                checkTrigger="none"
                onChange={onValueChange}
                onCheck={setFormError}
                formValue={formValue}
                model={schema}
            >
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                    <Form.Group controlId="mobileNumber-6">
                        <Form.ControlLabel>Mobile Number</Form.ControlLabel>
                        <Form.Control name="mobile_number" accepter={MobileNumberInput} errorMessage={formError.mobile_number} />
                    </Form.Group>
                    <Form.Group controlId="altMobileNumber-6">
                        <Form.ControlLabel>Alternate Mobile Number</Form.ControlLabel>
                        <Form.Control name="alt_mobile_number" accepter={MobileNumberInput} errorMessage={formError.alt_mobile_number} />
                    </Form.Group>
                    <Form.Group controlId="emergencyContact-6">
                        <Form.ControlLabel>Emergency Contact Number</Form.ControlLabel>
                        <Form.Control name="emergency_contact" accepter={EmergencyNumberInput} errorMessage={formError.emergency_contact} />
                    </Form.Group>
                    <Form.Group controlId="emailAddress-6">
                        <Form.ControlLabel>Email Address</Form.ControlLabel>
                        <Form.Control name="email" errorMessage={formError.email} />
                    </Form.Group>
                    <Form.Group controlId="presentAdd-6">
                        <Form.ControlLabel>Present Address</Form.ControlLabel>
                        <Form.Control name="present_address" accepter={Textarea} errorMessage={formError.present_address} />
                    </Form.Group>
                    <Form.Group controlId="permanentAdd-6">
                        <Form.ControlLabel>Permanent Address</Form.ControlLabel>
                        <Form.Control name="permanent_address" accepter={Textarea} errorMessage={formError.permanent_address} />
                    </Form.Group>
                    <Form.Group>
                        <ButtonToolbar>
                            {/* <Button appearance="default">Clear</Button> */}
                            <Button appearance="primary" onClick={() => setStep(0)}><FiChevronLeft className='inline-block' /> Previous</Button>
                            <Button appearance="primary" onClick={stepNext}>Continue <FiChevronRight className='inline-block' /> </Button>
                        </ButtonToolbar>
                    </Form.Group>
                </div>
            </Form>
        </div>
    )
}

export default ContactDetails