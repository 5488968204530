import { forwardRef } from "react";
import { Link } from "react-router-dom";

export const NavLink = forwardRef((props: any, ref) => {
    const { href, children } = props;
    return (
        <Link to={href}>
            {children}
        </Link>
    );
});