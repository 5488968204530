import axios from "axios";

export const getCompanies = async () => {
    return await axios.get('/api/resource/PD-Company?fields=["name","comp_name","address"]');
}

export const postCompany = async (values: any) => {
    return await axios.post('/api/resource/PD-Company', values);
}

export const getDepartmentsAPI = async (companyName: string) => {
    return await axios.get(`/api/resource/PD-Department?fields=["dept_name","company","name"]&filters=[["company","=","${companyName}"]]`);
}

export const getDesignationsAPI = async (companyName: string) => {
    return await axios.get(`/api/resource/PD-Designation?fields=["name","desig_name","company"]&filters=[["company","=","${companyName}"]]`);
}

export const getCompanyDocsAPI = async (companyName: string) => {
    return await axios.get(`/api/resource/PD-CompanyDoc?fields=["*"]&filters=[["belongs_to", "=", "${companyName}"]]`);
}