import { LoginAPI, LogoutAPI } from './../services/Auth';
import { useEffect, useState } from "react";
import Cookies from 'universal-cookie';

interface LoginData {
    usr: string, pwd: string
}

export interface IUseAuthProvider {
    logIn: (data: LoginData) => Promise<{ error: boolean, message: string }>;
    logOut: () => void;
    isAuthenticated: () => void;
    isLoading: boolean;
    user: any;
}

export const useAuthProvider = (): IUseAuthProvider => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const cookies = new Cookies();

    useEffect(() => {
        const cookie = cookies.getAll();
        console.log("🚀 ~ file: AuthProvider.ts:22 ~ useEffect ~ cookie:", cookie)
        if (cookie.user_id && cookie.system_user == 'yes') {
            console.log("User found")
            setUser(cookie);
        }
        setIsLoading(false);
    }, [])


    const logIn = async (loginData: LoginData) => {
        const response = await LoginAPI(loginData);
        if (response.status === 200) {
            setUser(cookies.getAll());
        }
        return { error: true, message: '' };
    }

    const logOut = async () => {
        const response = await LogoutAPI();
        if (response.status === 200) {
            setUser(null);
        }
    }

    const isAuthenticated = () => {
        const cookie = cookies.getAll();
        console.log('🚀 ~ file: AuthProvider.ts ~ line 34 ~ isAuthenticated ~ cookie', cookie);
        if (cookie.user_id !== undefined && cookie.user_id !== null && cookie.user_id !== '' && cookie.system_user == 'yes') {
            return true;
        }
        return false;
    }

    return { user, logIn, logOut, isAuthenticated, isLoading };
}
