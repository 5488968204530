export const DOCFROM_INITIAL = [
    { docType: '', docName: '', docID: '', dtIssued: null, dtExpiry: null, status: '', file: null },
    { docType: '', docName: '', docID: '', dtIssued: null, dtExpiry: null, status: '', file: null },
];

export const PERSONALFORM = {
    profile_pic: null,
    first_name: '',
    middle_name: '',
    last_name: '',
    employee_number: '',
    date_of_birth: null,
    gender: '',
    marital_status: '',
    father_name: '',
    mother_name: '',
    religion: '',
    edu_qualification: ''
}

export const CONTACTFORM = {
    mobile_number: '',
    alt_mobile_number: '',
    emergency_contact: '',
    email: '',
    present_address: '',
    permanent_address: ''
}

export const OFFICIALFORM = {
    date_of_joining: null,
    job_type: '',
    department: '',
    grade: '',
    designation: '',
    work_site: '',
    reporting_manager: ''
}

export const DOCUMENTFORM = {
    document_type: '',
    document_id: '',
    document_name: '',
    issued_date: null,
    expiry_date: null,
    attachment: ''
}

export const FILELIST = { url: '', fileKey: 0, name: '' }