import React from 'react'
import { Breadcrumb } from 'rsuite'
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import { NavLink } from '../utils/NavLink';

const AddDocument = () => {
    return (
        <div>
            <Breadcrumb separator={<AngleRightIcon />} className='mb-3'>
                <Breadcrumb.Item as={NavLink} href="/documents">Documents</Breadcrumb.Item>
                <Breadcrumb.Item active>Add Document</Breadcrumb.Item>
            </Breadcrumb>
        </div>
    )
}

export default AddDocument