import React, { createContext, useContext, useEffect, useState } from 'react';
import { getCompanies } from '../services/Company';
import { useAuth } from './AuthContextProvider';

const CompanyContext = createContext<any>(null);
export const CompanyContextProvider = ({ children }: any) => {
    const [activeCompany, setActiveCompany] = useState<any>();
    const [companies, setCompanies] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);
    const authContext = useAuth();
    const isAuth = authContext?.isAuthenticated();

    const getData = async () => {
        const data = (await getCompanies()).data;
        console.log('🚀 ~ file: CompanyContextProvider.tsx ~ line 12 ~ getData ~ data', data);
        setCompanies(data.data);
        setActiveCompanyToLS(data.data[0]);
        setIsLoading(false);
    };

    useEffect(() => {
        console.log('Inside useEffect CCP')
        if (isAuth) {
            console.log(true);
            getData();
        }
        const companyDetails = localStorage.getItem('companySelected');
        console.log('🚀 ~ file: CompanyContextProvider.tsx ~ line 9 ~ useEffect ~ companyDetails', typeof companyDetails);
        if (companyDetails !== null && companyDetails !== 'undefined') {
            setActiveCompany(JSON.parse(companyDetails));
        }
    }, [isAuth])

    const setActiveCompanyToLS = (data: any) => {
        console.log('🚀 ~ file: CompanyContextProvider.tsx ~ line 25 ~ setActiveCompanyToLS ~ data', data);
        localStorage.setItem('companySelected', JSON.stringify(data));
        setActiveCompany(data);
    }


    return <CompanyContext.Provider value={{ isLoading, activeCompany, companies, setActiveCompanyToLS }}>{children}</CompanyContext.Provider>
}
export const useCompanyContext = () => useContext(CompanyContext);