import { Icon } from '@rsuite/icons';
import SearchIcon from '@rsuite/icons/Search';
import { differenceInDays } from 'date-fns';
import moment from 'moment';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { BsFillGridFill, BsFilter, BsPrinterFill, BsThreeDotsVertical } from 'react-icons/bs';
import { FiDownload, FiEdit3, FiRefreshCcw, FiTrash2 } from 'react-icons/fi';
import { GoChevronRight } from 'react-icons/go';
import { MdViewStream } from 'react-icons/md';
import { TbDownload } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, ButtonToolbar, Checkbox, CheckboxGroup, Dropdown, IconButton, Input, InputGroup, Message, Popover, Radio, RadioGroup, Table, Whisper, useToaster } from 'rsuite';
import { DOCUMENTCOLUMNS } from '../constants/DOCUMENTS';
import { DocumentModel } from '../models/EMP_DOCS';
import { getCompanyDocsAPI } from '../services/Company';
import { useCompanyContext } from '../utils/CompanyContextProvider';
const { Column, HeaderCell, Cell } = Table;

const MenuPopover = forwardRef(({ data, onSelect, ...rest }: any, ref) => (
    <Popover ref={ref} {...rest} full>
        <Dropdown.Menu onSelect={onSelect}>
            <Dropdown.Item eventKey={1}>
                <div className="flex items-center">
                    <FiEdit3 /><div className='pl-1'>Edit</div>
                </div>
            </Dropdown.Item>
            <Dropdown.Item eventKey={2}>
                <div className="flex items-center">
                    <FiTrash2 /><div className='pl-1'>Delete</div>
                </div>
            </Dropdown.Item>
            <Dropdown.Item eventKey={3}>
                <div className="flex items-center">
                    <FiDownload /><div className='pl-1'>Download</div>
                </div>
            </Dropdown.Item>
            {
                data.status === 'Expired' &&
                <Dropdown.Item eventKey={4}>
                    <div className="flex items-center">
                        <FiRefreshCcw /><div className='pl-1'>Renew</div>
                    </div>
                </Dropdown.Item>
            }
        </Dropdown.Menu>
    </Popover>
));


interface FilterType {
    docType: number;
    status: number;
    expiresIn: number;
}



const Documents = () => {
    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortType, setSortType] = useState<any>();
    const [editRow, setEditRow] = useState<DocumentModel>();
    const [popupState, setPopupState] = useState<{ delete: boolean, edit: boolean, add: boolean }>({ delete: false, edit: false, add: false });
    const [documents, setDocuments] = useState<DocumentModel[]>([]);
    const displayColumns = DOCUMENTCOLUMNS;
    const [degree, setDegree] = useState<FilterType>({ docType: 0, status: 0, expiresIn: 0 })
    const ref = useRef<any>();
    const { activeCompany } = useCompanyContext();
    const toaster = useToaster();

    const message = (
        <Message showIcon type='error' closable>
            Message
        </Message>
    );


    useEffect(() => {
        const getDocuments = async () => {
            const data = (await getCompanyDocsAPI(activeCompany.comp_name)).data;
            toaster.push(message, { placement: 'topEnd', duration: 5000 })
            console.log("🚀 ~ file: Documents.tsx:70 ~ getDocuments ~a:", data)
            setDocuments(data.data);
        }
        getDocuments();
    }, [])


    const getData = () => {
        if (sortColumn && sortType) {
            return documents.sort((a, b) => {
                let x: any = a[sortColumn as keyof DocumentModel];
                let y: any = b[sortColumn as keyof DocumentModel];
                if (typeof x === 'string') {
                    x = x.charCodeAt(0);
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt(0);
                }
                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }
        return documents;
    };


    const handleSortColumn = (sortColumn: string, sortType: any) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }

    const onSelect = (id: any, data: any) => {
        switch (id) {
            case 1:
                setPopupState({ ...popupState, edit: true });
                setEditRow(data);
                break;
            case 2: setPopupState({ ...popupState, delete: true });
                break;
            default:
                break;
        }
        ref.current.close();
    }

    const rotateIcon = (context: string) => {
        let rotateTo = degree[context as keyof FilterType] === 0 ? 90 : 0;
        const d = { docType: 0, status: 0, expiresIn: 0 }
        setDegree({ ...d, [context]: rotateTo })
    }

    const FilterPopover = (
        <Popover arrow={false} className='w-52'>
            <div className='py-2'>
                <div onClick={() => rotateIcon('docType')} className='font-medium cursor-pointer'><Icon as={GoChevronRight} rotate={degree.docType} /> Document Type</div>
                <CheckboxGroup name="checkboxList" className={`ml-2 max-h-36 overflow-y-scroll ${degree.docType === 90 ? '!flex' : '!hidden'}`}>
                    <Checkbox value="A">Visa</Checkbox>
                    <Checkbox value="B">Passport</Checkbox>
                    <Checkbox value="C">Emirates ID</Checkbox>
                    <Checkbox value="C">Emirates ID</Checkbox>
                    <Checkbox value="C">Emirates ID</Checkbox>
                    <Checkbox value="C">Emirates ID</Checkbox>
                </CheckboxGroup>

            </div>
            <div className='py-2'>
                <div onClick={() => rotateIcon('status')} className='font-medium cursor-pointer'><Icon as={GoChevronRight} rotate={degree.status} /> Document Status</div>
                <CheckboxGroup name="checkboxList" className={`ml-2 max-h-36 overflow-y-scroll ${degree.status === 90 ? '!flex' : '!hidden'}`}>
                    <Checkbox value="A">Active</Checkbox>
                    <Checkbox value="B">Grace Period</Checkbox>
                    <Checkbox value="C">Voilated</Checkbox>
                </CheckboxGroup>

            </div>
            <div className='py-2'>
                <div onClick={() => rotateIcon('expiresIn')} className='font-medium cursor-pointer'><Icon as={GoChevronRight} rotate={degree.expiresIn} /> Expires In</div>
                <RadioGroup name="radioList" className={`ml-2 max-h-36 overflow-y-scroll ${degree.expiresIn === 90 ? '!flex' : '!hidden'}`}>
                    <Radio value="A">In 7 Days</Radio>
                    <Radio value="B">In 15 Days</Radio>
                    <Radio value="B">In 30 Days</Radio>
                    <Radio value="B">In 60 Days</Radio>
                </RadioGroup>
            </div>
        </Popover>
    )


    return (
        <div>

            <div className="flex justify-between mb-4">
                <div>
                    <div className="text-lg">Company Documents</div>
                </div>
                <Link to='add'>
                    <Button appearance="primary" className='bg-blue-700 hover:bg-blue-800'>+ Add Document</Button>
                </Link>
            </div>
            <div className="text-xs text-slate-500 mb-4">Simplify document management: upload, edit, and delete company files effortlessly</div>
            <div className="flex justify-end mb-2">

            </div>
            <div className="sm:flex flex-row mb-4">
                <InputGroup inside className='grow !w-80'>
                    <InputGroup.Addon><SearchIcon /></InputGroup.Addon>
                    <Input type='search' />
                </InputGroup>
                <Whisper
                    placement="bottomStart"
                    trigger="click"
                    speaker={FilterPopover}
                >
                    <Button className='!bg-white !border ml-2'>
                        <div className='flex items-center'>
                            <BsFilter className='mr-1' size={16} /> Filter
                        </div>
                    </Button>
                </Whisper>
                <ButtonToolbar className='text-right ml-3 self-center'>
                    <IconButton className='!bg-white' icon={<TbDownload size={16} />} />
                    <IconButton className='!bg-white' icon={<BsPrinterFill size={16} />} />
                    <ButtonGroup>
                        <IconButton className='!bg-white' icon={<BsFillGridFill size={16} />} appearance="subtle" />
                        <IconButton icon={<MdViewStream size={16} />} appearance="primary" />
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
            <div className='bg-white rounded-lg p-4'>
                <Table
                    data={getData()}
                    height={300}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={handleSortColumn}
                    affixHeader
                >
                    {displayColumns.map(column => {
                        const { key, label, ...rest } = column;
                        return (
                            <Column {...rest} key={key} sortable>
                                <HeaderCell>{label}</HeaderCell>
                                <Cell dataKey={key} />
                            </Column>
                        );
                    })}
                    <Column key='status' sortable>
                        <HeaderCell>Status</HeaderCell>
                        <Cell>
                            {rowData =>
                                <div className={`p-0.5 text-center text-xs rounded  ${rowData.status === 'Expired' ? 'text-red-600 bg-red-100' : 'text-green-600 bg-green-100'}`}>{rowData.status.toUpperCase()}</div>
                            }
                        </Cell>
                    </Column>
                    <Column key='expiresIn' sortable>
                        <HeaderCell>Expires In</HeaderCell>
                        <Cell>{rowData => `${differenceInDays(moment(rowData.expiry_date, 'YYYY-MM-DD').toDate(), new Date())} days`}</Cell>
                    </Column>
                    <Column key='action'>
                        <HeaderCell>Action</HeaderCell>
                        <Cell className='text-end'>
                            {rowData =>
                                <Whisper ref={ref} trigger="click" placement='bottom' speaker={<MenuPopover data={rowData} onSelect={(id: number) => onSelect(id, rowData)} />}>
                                    <span className='flex justify-center'>
                                        <BsThreeDotsVertical size={18} className='text-gray-400 hover:text-gray-800 cursor-pointer' />
                                    </span>
                                </Whisper>
                            }
                        </Cell>
                    </Column>
                </Table>
            </div>
        </div>
    )
}

export default Documents