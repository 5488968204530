import React, { createContext, useContext } from 'react';
import { IUseAuthProvider, useAuthProvider } from '../helpers/AuthProvider';

const AuthContext = createContext<IUseAuthProvider | null>(null);

export const AuthContextProvider = ({ children }: any) => {
    const auth = useAuthProvider();
    console.log('🚀 ~ file: AuthContextProvider.tsx ~ line 8 ~ AuthContextProvider ~ auth', auth);
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext);