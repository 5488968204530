import { useState, useRef, useEffect } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Button, ButtonToolbar, DatePicker, Form, InputPicker } from 'rsuite';
import { OFFICIALFORM } from '../constants/INITIAL_STATES';
import { DEPARTMENTS, DESIGNATIONS, JOBTYPE } from '../constants/JOBDATA';
import { OFFICIAL_SCHEMA } from '../helpers/Validators';
import { OFFICIALMODEL } from '../models/BIODATA';
import { useCompanyContext } from '../utils/CompanyContextProvider';
import { getDepartmentsAPI, getDesignationsAPI } from '../services/Company';

const OfficialDetails = ({ setStep, formData, onChange }: any) => {
    const jobTypeData = JOBTYPE;
    const departmentData = DEPARTMENTS;
    const desingantionData = DESIGNATIONS;
    const schema = OFFICIAL_SCHEMA;

    const formRef = useRef<any>();
    const { activeCompany } = useCompanyContext();
    const [formError, setFormError] = useState<any>({});
    const [formValue, setFormValue] = useState<OFFICIALMODEL>(OFFICIALFORM);
    const [departments, setDepartments] = useState<any>([]);
    const [designations, setDesignations] = useState<any>([]);
    const onValueChange = (data: any) => {
        setFormValue(data);
    }

    useEffect(() => {
        setFormValue(formData)
    }, [formData])

    useEffect(() => {
        console.log(activeCompany);
        const getDepartment = async () => {
            const depts = (await getDepartmentsAPI(activeCompany.comp_name)).data;
            console.log("🚀 ~ file: OfficialDetails.tsx:35 ~ getDepartment ~ depts:", depts)
            const updatedDepts = depts.data.map((item: any) => ({
                label: item.dept_name,
                value: item.name
            }));
            setDepartments(updatedDepts);
        }
        const getDesignation = async () => {
            const desigs = (await getDesignationsAPI(activeCompany.comp_name)).data;
            console.log("🚀 ~ file: OfficialDetails.tsx:43 ~ getDesignation ~ desigs:", desigs)
            const updatedDesigs = desigs.data.map((item: any) => ({
                label: item.desig_name,
                value: item.name
            }));
            setDesignations(updatedDesigs);
        }
        getDepartment();
        getDesignation();
    }, [])



    const submitData = () => {
        if (!formRef.current.check()) {
            return;
        }
        setStep(3);
        onChange(formValue, 'official');
    }

    return (
        <div className="w-full sm:w-3/4">
            <Form fluid
                ref={formRef}
                checkTrigger="none"
                onChange={onValueChange}
                onCheck={setFormError}
                formValue={formValue}
                model={schema}
            >
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5">
                    <Form.Group controlId="dtJoining-6">
                        <Form.ControlLabel>Date of Joining</Form.ControlLabel>
                        <Form.Control name="date_of_joining" accepter={DatePicker} errorMessage={formError.date_of_joining} format="dd-MM-yyyy" oneTap block />
                    </Form.Group>
                    <Form.Group controlId="jobType-6">
                        <Form.ControlLabel>Job Type</Form.ControlLabel>
                        <Form.Control name="job_type" accepter={InputPicker} errorMessage={formError.job_type} data={jobTypeData} block />
                    </Form.Group>
                    <Form.Group controlId="department-6">
                        <Form.ControlLabel>Department</Form.ControlLabel>
                        <Form.Control name="department" accepter={InputPicker} errorMessage={formError.department} data={departments} block />
                    </Form.Group>
                    <Form.Group controlId="grade-6">
                        <Form.ControlLabel>Grade</Form.ControlLabel>
                        <Form.Control name="grade" errorMessage={formError.grade} />
                    </Form.Group>
                    <Form.Group controlId="designation-6">
                        <Form.ControlLabel>Designation</Form.ControlLabel>
                        <Form.Control name="designation" accepter={InputPicker} errorMessage={formError.designation} data={designations} block />
                    </Form.Group>
                    <Form.Group controlId="workSite-6">
                        <Form.ControlLabel>Work Site</Form.ControlLabel>
                        <Form.Control name="work_site" errorMessage={formError.work_site} />
                    </Form.Group>
                    <Form.Group controlId="manager-6">
                        <Form.ControlLabel>Reporting Manager</Form.ControlLabel>
                        <Form.Control name="reporting_manager" errorMessage={formError.reporting_manager} />
                    </Form.Group>
                    <Form.Group></Form.Group>
                    <Form.Group>
                        <ButtonToolbar>
                            {/* <Button appearance="default">Clear</Button> */}
                            <Button appearance="primary" onClick={() => setStep(1)}><FiChevronLeft className='inline-block' /> Previous</Button>
                            <Button appearance="primary" onClick={submitData}>Finish</Button>
                        </ButtonToolbar>
                    </Form.Group>
                </div>
            </Form>
        </div>
    )
}

export default OfficialDetails